import request from '@/utils/request'

// 投入统计
export function marketInputList(data) {
  return request({
    url: '/reportAdmin/analysis/market-input',
    method: 'post',
    data,
  })
}

// 铺市率按品牌
export function inBrandList(data) {
  return request({
    url: '/reportAdmin/analysis/market-brand-list',
    method: 'post',
    data,
  })
}

// 铺市率按商品
export function inClassList(data) {
  return request({
    url: '/reportAdmin/analysis/market-goods-list',
    method: 'post',
    data,
  })
}

// 客户分析列表
export function clientAnalysisList(data) {
  return request({
    url: '/reportAdmin/analysis/cust-sale-list',
    method: 'post',
    data,
  })
}
